import { cn } from "@/lib/utils"

interface PauseIconProps {
	className?: string
}

export default function PauseIcon({className}:PauseIconProps) {
	return (
		<div className={cn('w-[24px] h-[24px] sm:w-[40px] sm:h-[40px] md:w-[48px] md:h-[48px] rounded-full bg-white/80 hover:bg-white transition flex items-center justify-center', className)}>
			<svg className={`w-2/5 h-auto text-black`} viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.31898 30H0.884998C0.396479 30 0 29.5713 0 29.0432V0.956816C0 0.428653 0.396479 0 0.884998 0H8.31898C8.8075 0 9.20398 0.428653 9.20398 0.956816V29.0441C9.20398 29.5723 8.8075 30 8.31898 30Z" fill="currentColor" />
				<path d="M24.4264 30H16.9924C16.5039 30 16.1074 29.5713 16.1074 29.0432V0.956816C16.1074 0.428653 16.5039 0 16.9924 0H24.4264C24.9149 0 25.3114 0.428653 25.3114 0.956816V29.0441C25.3114 29.5723 24.9149 30 24.4264 30Z" fill="currentColor" />
			</svg>
		</div>
	)
}