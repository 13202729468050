import { useRef, useState, useEffect } from 'react'
import RichText from '@/components/RichText'
import { PortableTextBlock } from '@portabletext/types'

const HomeIntroStrapline = ({ heading, className }: { heading: PortableTextBlock[]; className?: string }) => {
	const elRef = useRef<HTMLDivElement>(null)
	const [showStrapline, setShowStrapline] = useState(false)
	const threshold = 0

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
        entries.forEach((entry) => {
          console.log('entry.isIntersecting', entry.isIntersecting)
          setShowStrapline(entry.isIntersecting)
      })
			},
			{ threshold, rootMargin: "0px 0px -35% 0px", }
		)

		const currentElement = elRef.current
		if (currentElement) {
			observer.observe(currentElement)
		}

		return () => {
			if (currentElement) {
				observer.unobserve(currentElement)
			}
		}
	}, [threshold])
	return (
		<div ref={elRef} className={className}>
			<div
				className="flex flex-col justify-start col-span-10 md:col-span-8 col-start-2 md:col-start-3 text-center"
				style={{
					alignItems: 'center',
					opacity: showStrapline ? 1 : 0,
					transform: showStrapline ? 'translateY(0)' : 'translateY(10%)',
					transition: 'opacity 0.8s ease, transform 1s ease-in-out'
				}}>
				<div className="style-h2 [&_em]:italic [@media(max-width:767px)]:[&_br]:hidden">
					<RichText content={heading} />
				</div>
			</div>
		</div>
	)
}

export default HomeIntroStrapline
