import { cn } from "@/lib/utils"

interface PlayIconProps {
	className?: string
}

export default function PlayIcon({className}:PlayIconProps) {
	return (
		<div className={cn('w-[28px] h-[28px] sm:w-[44px] sm:h-[44px] md:w-[64px] md:h-[64px] rounded-full bg-white/80 hover:bg-white transition flex items-center justify-center', className)}>
			<svg className={`translate-x-[10%] w-1/2 h-auto text-black`} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.445312 1.31675V31.0759C0.445312 31.79 1.22225 32.2338 1.8377 31.8703L26.6617 17.1997C27.2615 16.8454 27.267 15.9791 26.6718 15.6174L1.84693 0.528839C1.23239 0.155178 0.445312 0.59711 0.445312 1.31675Z" fill="currentColor"/></svg>
		</div>
	)
}