'use client'

import { SanityImageAssetDocument } from 'next-sanity'
import Image from 'next/image'
import { urlForImage } from '@/sanity/lib/image'

import VideoPlayer from '@/components/VideoPlayer'

export interface HomeProjectImageInterface extends SanityImageAssetDocument {
	desktop?: {
		span: number
		column: number
		row: number
	}
	mobile?: {
		span: number
		column: number
		row: number
	}
}

interface HomeProjectImageProps {
	media: HomeProjectImageInterface
	gridPosition: any
	index: number
}

const HomeProjectImage = ({ media, gridPosition, index }: HomeProjectImageProps) => {

	let spanClasses = 'collage-item-span-'

	if (gridPosition?.desktop?.span) {
		spanClasses += gridPosition?.desktop?.span
	} else {
		spanClasses += '4'
	}

	if (gridPosition?.mobile?.span) {
		spanClasses += ` mobile:collage-item-span-${gridPosition?.mobile?.span}`
		spanClasses += ` mobile:collage-item-span-${gridPosition?.mobile?.span}`
	} else {
		spanClasses += ` mobile:collage-item-span-4`
		spanClasses += ` mobile:collage-item-span-4`
	}

	let columnClasses = 'collage-item-col-start-'

	if (gridPosition?.desktop?.column) {
		columnClasses += gridPosition?.desktop?.column
	} else {
		columnClasses += 'auto'
	}

	if (gridPosition?.mobile?.column) {
		columnClasses += ` mobile:collage-item-col-start-${gridPosition?.mobile?.column}`
		columnClasses += ` mobile:collage-item-col-start-${gridPosition?.mobile?.column}`
	} else {
		columnClasses += ` mobile:collage-item-col-start-auto`
		columnClasses += ` mobile:collage-item-col-start-auto`
	}

	let rowClasses = 'collage-item-row-start-'

	if (gridPosition?.desktop?.row) {
		rowClasses += gridPosition?.desktop?.row
	} else {
		rowClasses += 'auto'
	}

	if (gridPosition?.mobile?.row) {
		rowClasses += ` mobile:collage-item-row-start-${gridPosition?.mobile?.row}`
		rowClasses += ` mobile:collage-item-row-start-${gridPosition?.mobile?.row}`
	} else {
		rowClasses += ` mobile:collage-item-row-start-auto`
		rowClasses += ` mobile:collage-item-row-start-auto`
	}

	return (
		<div key={`${media?.type}-${index}`} className={`relative collage-item ${spanClasses} ${columnClasses} ${rowClasses}`}>
			{media?.type === 'image' && (
				<Image
					className={`absolute w-full h-auto rounded-weak overflow-hidden`}
					src={urlForImage(media?.image).url()}
					width={media?.image?.asset?.metadata?.dimensions?.width}
					height={media?.image?.asset?.metadata?.dimensions?.height}
					sizes="(min-width: 712px) 25vw, 33vw"
					alt={media?.image?.alt || ''}
					placeholder="blur"
					blurDataURL={media?.image?.asset?.metadata?.lqip}
					unoptimized={media?.image?.asset?.url?.includes(".gif") ? true : false} // mark Gif images as unoptimized (Next.js does not support Gif optimisation yet)
				/>
			)}

			{media?.type === 'video' && media?.video?.asset?.playbackId && (
				<div className="absolute w-full h-auto rounded-weak overflow-hidden">
					<VideoPlayer
						playbackId={media?.video?.asset?.playbackId}
						showAudioToggle={false}
						autoplay={true}
						loop={true}
						posterTime={media?.posterTime}
					/>
				</div>
			)}
		</div>
	)
}

export default HomeProjectImage