import React from 'react'
import { cn } from '@/lib/utils'
import VideoPlayer from '@/components/VideoPlayer'

interface HomeShowreelProps {
	showreel: any
	showreelId: string
	rectClipId: string
	children?: React.ReactNode
	className?: string
	style?: React.CSSProperties
}

const HomeShowreel = ({ showreel, showreelId, rectClipId, children, className, style = {} }: HomeShowreelProps) => {
	const videoAspectRatio = 16 / 9
	const paddingTop = 100 / videoAspectRatio
	return (
		<div
			id={showreelId}
			className={cn('w-full', className)}
			style={{
				...style,
				opacity: 0,
				transformOrigin: 'center top',
				willChange: 'transform, opacity'
			}}>
			<div
				className={cn('relative w-full')}
				style={{
					paddingTop: `${paddingTop}%`
				}}>
				<div
					className="absolute top-0 left-0 w-full h-full bg-black overflow-hidden rounded-weak"
					style={{
						clipPath: 'url(#circleClip)'
					}}>
					<VideoPlayer
						playbackId={showreel?.video?.asset?.playbackId}
						showAudioToggle={true}
						autoplay={true}
						loop={true}
						posterTime={showreel?.posterTime}
					/>
				</div>
				{children}
			</div>
		</div>
	)
}

export default HomeShowreel
