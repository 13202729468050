'use client'

import { useCallback, useRef, useState } from 'react'
import RichText from '@/components/RichText'
import HomeShowreel from '@/components/HomeShowreel'
import HomeIntroStrapline from '@/components/HomeIntroStrapline'
import HereLogo from '@/components/logos/HereLogo'
import { PortableTextBlock } from '@portabletext/types'
import { useEffect } from 'react'
import { mapRange } from '@/lib/utils'
import useApp from '@/lib/store'

interface HomeIntroProps {
	heading: PortableTextBlock[]
	showreel: any
}

const SEQUENCES = {
	mobile: {
		showreelScaleUp: {
			from: [0, 30], // scroll percent
			to: [1, 1] // scale
		}
	},
	desktop: {
		showreelScaleUp: {
			from: [0, 45], // scroll percent
			to: [0.2, 1] // scale
		}
	}
}

const animateIntro = ({
	isMobile,
	logoEl,
	showreelVideoEl,
	scrollPercent,
	windowWidth
}: {
	isMobile: boolean
	logoEl: HTMLElement
	showreelVideoEl: HTMLDivElement
	scrollPercent: number
	windowWidth: number
}) => {
	// SHOWREEL SCALE UP
	const { showreelScaleUp } = isMobile ? SEQUENCES.mobile : SEQUENCES.desktop
	const { from: showreelScaleUpFrom, to: showreelScalUpTo } = showreelScaleUp
	const showreelScaleUpMax = showreelScaleUpFrom[1]
	if (scrollPercent < showreelScaleUpMax) {
		const scale = mapRange(
			scrollPercent,
			showreelScaleUpFrom[0],
			showreelScaleUpFrom[1],
			showreelScalUpTo[0],
			showreelScalUpTo[1]
		)
		showreelVideoEl.style.transform = `scale(${scale})`
	}

	if (scrollPercent > showreelScaleUpMax - 5) {
		logoEl.style.opacity = `${0}`
	} else {
		logoEl.style.opacity = `${1}`
	}
}

export default function HomeIntro({ heading, showreel }: HomeIntroProps) {
	const scrollY = useRef(0)
	const scrollYPrev = useRef(0)

	const logoId = 'IntroLogo'
	const containerId = 'HomeIntro'
	const rectClipId = 'ShowreelRectClip'
	const showreelContainerId = 'ShowreelContainer'
	const showreelVideoId = 'ShowreelVideo'

	const showreelPeekAmount = 40

	const { setIntroScrollProgress } = useApp()
	const [showStrapline, setShowStrapline] = useState(false)

	const runShowStrapline = useCallback(() => {
		setShowStrapline(true)
	}, [])

	useEffect(() => {
		// Get ELS
		const logoEl = document.getElementById(logoId) as HTMLElement
		const containerEl = document.getElementById(containerId) as HTMLDivElement
		const showreelContainerEl = document.getElementById(showreelContainerId) as HTMLDivElement
		const showreelVideoEl = document.getElementById(showreelVideoId) as HTMLDivElement
		let windowWidth = window.innerWidth
		const isMobile = windowWidth < 768
		const { showreelScaleUp } = isMobile ? SEQUENCES.mobile : SEQUENCES.desktop
		
		// Handle resize
		let containerHeight
		const onResize = () => {
			const windowHeight = window.innerHeight
			windowWidth = window.innerWidth
			// Measure container height for scrolling purposes
			containerHeight = containerEl.offsetHeight
			// Position the showreel container
			const showreelVideoHeight = showreelVideoEl.getBoundingClientRect().height
			const showreelTop = (windowHeight - showreelVideoHeight) / 2 + showreelPeekAmount
			showreelContainerEl.style.top = `${showreelTop}px`
		}
		onResize()
		// Listen for scroll
		const onScroll = () => {
			scrollY.current = Math.max(window.scrollY, 0)
		}
		onScroll()
		// Animate things as scroll progresses
		const animateScroll = () => {
			const diffScroll = scrollY.current - scrollYPrev.current
			const speed = 1
			const newScroll = scrollYPrev.current + diffScroll * speed
			scrollYPrev.current = newScroll
			const scrollPercent = (newScroll / containerHeight) * 100
			if (scrollPercent < 100) {
				setIntroScrollProgress(scrollPercent)
				animateIntro({ isMobile, logoEl, showreelVideoEl, scrollPercent, windowWidth })
			}
			tick = requestAnimationFrame(animateScroll)
		}
		let tick = requestAnimationFrame(animateScroll)
		window.addEventListener('resize', onResize)
		window.addEventListener('scroll', onScroll)
		// Show showreel
		showreelVideoEl.style.opacity = '1'
		return () => {
			cancelAnimationFrame(tick)
			window.removeEventListener('resize', onResize)
			window.removeEventListener('scroll', onScroll)
		}
	}, [])

	return (
		<div
			id={containerId}
			className="max-w-[1824px] mx-auto mb-[25svh]"
			style={{
				marginTop: 'calc(var(--header-height) * -1)'
			}}>
			<div className={`relative w-full min-h-screen-less-header-height px-site-margin overflow-x-clip auto-rows-auto`}>
				{/* LOGO */}
				<div
					className="sticky top-0 grid grid-cols-12 gap-8 w-full h-screen"
					style={{
						alignItems: 'center'
					}}>
					<div
						className="intro--logo col-span-10 md:col-span-8 col-start-2 md:col-start-3 p-2"
						style={{
							aspectRatio: '16/9'
						}}>
						<HereLogo
							id={logoId}
							className="w-full h-full"
							size="large"
							style={{
								willChange: 'transform',
								transform: 'translateX(1%)'
							}}
						/>
					</div>
				</div>

				{/* SHOWREEL */}
				<div
					id={showreelContainerId}
					className="md:grid grid-cols-12 gap-8 w-full h-intro"
					style={{
						alignItems: 'top'
					}}>
					<HomeShowreel
						showreel={showreel}
						style={{
							marginTop: -showreelPeekAmount,
							willChange: 'transform'
						}}
						className="md:col-span-10 md:col-start-2"
						showreelId={showreelVideoId}
						rectClipId={rectClipId}>
						{/* STRAPLINE */}
						<HomeIntroStrapline className="absolute bottom-0 left-0 w-full pt-16 translate-y-[100%] items-center" heading={heading} />
					</HomeShowreel>
				</div>
			</div>
		</div>
	)
}
