'use client'

import useInView from '@/lib/hooks/useInView'
import { urlForImage } from '@/sanity/lib/image'
import Image from 'next/image'
import Link from 'next/link'
import { Fragment } from 'react'

import VideoPlayer from '@/components/VideoPlayer'
import HomeProjectImage, { HomeProjectImageInterface} from '@/components/HomeProjectImage'


interface HomeProjectProps {
	title: string
	headline: string
	url: string
	hero: any
	heroMobile?: any
	images?: HomeProjectImageInterface[]
}

export default function HomeProject({ title, headline, url, hero, heroMobile, images }: HomeProjectProps) {
	const [ref, isInView] = useInView({
		threshold: 0.7,
		rootMargin: '0% 0% 0% 0%'
	})

	return (
		<div className="max-w-[1824px] mx-auto">
			{/* container */}
			<Link href={url} className="relative w-full min-h-[300lvh] text-black">
				{/* title */}
				<div className="sticky top-0 z-100 w-full h-[100lvh] flex items-center px-site-margin">
					<h2 className="w-full style-h1 text-center">{title}</h2>
				</div>

				{/* hero */}
				<div ref={ref} className={`sticky top-0 z-100 w-full h-[100lvh] px-site-margin mt-[25vh] grid items-center gap-4 grid-cols-12`}>

					<div className={`${heroMobile ? "mobile:hidden" : ""} col-span-12 col-start-1 sm:col-span-10 sm:col-start-2 md:col-span-8 md:col-start-3 rounded-weak overflow-hidden`}>

						{hero?.type === 'image' && (
							<Image
								className={`relative w-full h-auto`}
								src={urlForImage(hero?.image).url()}
								width={hero?.image?.asset?.metadata?.dimensions?.width}
								height={hero?.image?.asset?.metadata?.dimensions?.height}
								sizes="(min-width: 712px) 75vw, 90vw"
								alt={hero?.image?.alt || ''}
								placeholder="blur"
								blurDataURL={hero?.image?.asset?.metadata?.lqip}
								unoptimized={hero?.image?.asset?.url?.includes(".gif") ? true : false} // mark Gif images as unoptimized (Next.js does not support Gif optimisation yet)
							/>
						)}

						{hero?.type === 'video' && hero?.video?.asset?.playbackId && (
							<div className="relative w-full h-auto">
								<VideoPlayer
									playbackId={hero?.video?.asset?.playbackId}
									showAudioToggle={false}
									autoplay={true}
									loop={true}
									posterTime={hero?.posterTime}
								/>
							</div>
						)}

					</div>

					{/* mobile hero */}
					{heroMobile && (heroMobile?.image || heroMobile?.video) && (
						<div className={`hidden mobile:block col-span-12 col-start-1 sm:col-span-10 sm:col-start-2 md:col-span-8 md:col-start-3 rounded-weak overflow-hidden`}>

							{heroMobile?.type === 'image' && (
								<Image
									className={`relative w-full h-auto`}
									src={urlForImage(heroMobile?.image).url()}
									width={heroMobile?.image?.asset?.metadata?.dimensions?.width}
									height={heroMobile?.image?.asset?.metadata?.dimensions?.height}
									sizes="(min-width: 712px) 75vw, 90vw"
									alt={heroMobile?.image?.alt || ''}
									placeholder="blur"
									blurDataURL={heroMobile?.image?.asset?.metadata?.lqip}
									unoptimized={heroMobile?.image?.asset?.url?.includes(".gif") ? true : false} // mark Gif images as unoptimized (Next.js does not support Gif optimisation yet)
								/>
							)}

							{heroMobile?.type === 'video' && heroMobile?.video?.asset?.playbackId && (
								<div className="relative w-full h-auto">
									<VideoPlayer
										playbackId={heroMobile?.video?.asset?.playbackId}
										showAudioToggle={false}
										autoplay={true}
										loop={true}
										posterTime={heroMobile?.posterTime}
									/>
								</div>
							)}

						</div>
					)}

				</div>

				<div className="relative z-200 w-full pb-[70vh]">
					<div className="home-page-collage w-full min-h-[100lvh] md:aspect-square px-site-margin overflow-hidden">
						{images?.length > 0 &&
							images?.map((item, index) => (
								<Fragment key={`${item?.src}-${index}`}>
									<HomeProjectImage media={item?.media} gridPosition={item?.gridPosition} index={index} />
								</Fragment>
							))}
					</div>
				</div>

				<div
					className={`sticky bottom-0 left-0 w-full px-site-margin py-4 sm:py-8 sm:pb-6 ${isInView ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}>
					<p className="w-full sm:style-body--xl text-balance [&_em]:italic">{headline}</p>
				</div>
			</Link>
		</div>
	)
}

