'use client'

import PauseIcon from "@/components/icons/PauseIcon"
import PlayIcon from "@/components/icons/PlayIcon"
import SoundOffIcon from "@/components/icons/SoundOffIcon"
import SoundOnIcon from "@/components/icons/SoundOnIcon"
import useInView from "@/lib/hooks/useInView"
import { cn, pauseAndMuteOtherMedia } from "@/lib/utils"
import MuxVideo from '@mux/mux-video-react'
import { useEffect, useRef, useState } from "react"

interface VideoPlayerProps {
	playbackId: string
	showAudioToggle?: boolean
	autoplay?: boolean
	loop?: boolean
	posterTime?: number
	className?: string
}

export default function VideoPlayer({
	playbackId,
	showAudioToggle = true,
	autoplay = false,
	loop = false,
	posterTime,
	className=`block w-full h-full object-cover`
}:VideoPlayerProps) {
	const [isMuted, setIsMuted] = useState(autoplay)
	const [isPlaying, setIsPlaying] = useState(autoplay)
	const [containerRef, inView] = useInView({
		threshold: 0.1
	})
	const mediaRef = useRef<any>()

	const togglePlayPause = () => {
		pauseAndMuteOtherMedia(mediaRef.current)

		if (isPlaying) {
			mediaRef.current.pause()
			mediaRef.current.muted = true
		} else {
			mediaRef.current.play()
			mediaRef.current.muted = false
		}
	}

	const toggleSound = () => {
		if (isMuted) {
			mediaRef.current.muted = false
		} else {
			mediaRef.current.muted = true
		}
	}

	const playHandler = () => {
		console.log(`${playbackId} is playing`)
		setIsPlaying(true)
	}

	const pauseHandler = () => {
		console.log(`${playbackId} is paused`)
		setIsPlaying(false)
	}

	const endedHandler = () => {
		console.log(`${playbackId} has ended`)
		if (loop) {
			mediaRef.current.play() // force fix occasional pausing issue (https://github.com/Here-Design/here-website/issues/47)
		}
	}

	const volumeChangeHandler = () => {
		console.log(`${playbackId} is ${mediaRef.current.muted ? "muted" : "unmuted"}`)
		setIsMuted(mediaRef.current.muted)
	}

	useEffect(() => {
		if (!mediaRef.current) return

		if (isMuted) {
			mediaRef.current.muted = true
		} else {
			pauseAndMuteOtherMedia(mediaRef.current)
			mediaRef.current.muted = false
		}
	}, [isMuted])

	useEffect(() => {
		if (!mediaRef.current) return

		if (autoplay && inView) {
			mediaRef.current.play()
		}

		if (!inView) {
			mediaRef.current.pause()
		}
	}, [inView])

	useEffect(() => {
		if (!mediaRef.current) return

		mediaRef.current.addEventListener('play', playHandler)
		mediaRef.current.addEventListener('pause', pauseHandler)
		mediaRef.current.addEventListener('ended', endedHandler)
		mediaRef.current.addEventListener('volumechange', volumeChangeHandler)

		return () => {
			mediaRef.current.removeEventListener('play', playHandler)
			mediaRef.current.removeEventListener('pause', pauseHandler)
			mediaRef.current.removeEventListener('ended', endedHandler)
			mediaRef.current.removeEventListener('volumechange', volumeChangeHandler)
		}
	}, [])

	return (
		<div ref={containerRef} className="group relative w-full h-full overflow-clip">

			<div className="relative w-full h-full">

				{/* Image sets the size of the video - get rid of safari black border bug!  */}
				<img
					className="block relative z-0 w-full h-auto"
					src={`https://image.mux.com/${playbackId}/thumbnail.webp?width=192`}
					alt=""
				/>

				<div className="absolute z-100 top-[-2px] bottom-[-2px] left-[-2px] right-[-2px] overflow-clip">

					<MuxVideo
						ref={mediaRef}
						streamType="on-demand"
						playbackId={playbackId}
						poster={posterTime ? `https://image.mux.com/${playbackId}/thumbnail.webp?time=${posterTime}` : `https://image.mux.com/${playbackId}/thumbnail.webp?`}
						className={className}
						style={{
							'--media-object-fit': 'cover',
						} as React.CSSProperties}
						playsInline
						autoPlay={false} // handle autoplay when in viewport, if enabled
						loop={loop}
					/>

				</div>

			</div>

			{!autoplay && (
				<>
					<button
						className={`absolute inset-0 z-100 w-full h-full opacity-0`}
						onClick={togglePlayPause} />
						<button
						className={cn(`absolute z-200 transition-opacity duration-150`, {
							'inset-center': !isPlaying,
							'left-2 bottom-2 sm:left-6 sm:bottom-6 md:left-8 md:bottom-8': isPlaying,
							'opacity-100': !isPlaying,
							'opacity-0 group-hover:opacity-100': isPlaying
						})}
						onClick={togglePlayPause}>
						{isPlaying ? <PauseIcon /> : <PlayIcon />}
					</button>
				</>
			)}

			{showAudioToggle && isPlaying && (
				<div className={`absolute z-200 bottom-0 right-0 p-2 sm:p-6 md:p-8 ${isPlaying && isMuted ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} transition`}>
					<button className={`w-[24px] h-[24px] sm:w-[40px] sm:h-[40px] md:w-[48px] md:h-[48px] rounded-full bg-white/80 hover:bg-white transition flex items-center justify-center`} onClick={toggleSound}>
						{!isMuted ? <SoundOnIcon className="w-1/2 pointer-events-none" /> : <SoundOffIcon className="w-1/2 pointer-events-none" />}
					</button>
				</div>
			)}
		</div>
	)
}